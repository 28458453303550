export const teamStatusEnum1 = {
    0: '公开',
    1: '私有',
    2: '加密',
}

export const taskStatusEnum = {
    1: 'per item settlement',
    2: 'lottery',
    3: 'settlement by pet score',
}
export const taskStatusEnumcn = {
    1: '单件结算',
    2: '抽奖模式',
    3: '积分单件结算',
}

export const communityStatusEnum = {
    1: 'Allow',
    2: 'Do Not Allow',

}
export const communityStatusEnumcn = {
    1: '自由发言',
    2: '禁言模式',

}




